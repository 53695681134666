export default function ChartLoader() {
    return (
        <div>No Data</div>
        // <Spin
        //     indicator={
        //         <LoadingOutlined
        //             style={{
        //                 fontSize: 48,
        //                 color: "green",
        //             }}
        //             spin
        //         />
        //     }
        // />
    );
}
